import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IonButtons } from '@ionic/react'
import cx from 'classnames'
import formatISO from 'date-fns/formatISO'

import { Share } from '@/components/atoms/assets/Share'
import { MarkdownEllipsis } from '@/components/atoms/MarkdownEllipsis'
import { CommentsButton } from '@/components/molecules/CommentsButton'
import { ReactionButton } from '@/components/molecules/ReactionButton'
import { ShareButton } from '@/components/molecules/ShareButton'
import { IActivityItem } from '@/components/pages/Activity/interfaces'

import { appConfig } from '@services/config'
import { useUserInfo } from '@/lib/hooks/useUserInfo'
import { Tracking, TrackingEvent } from '@/lib/tracking'
import { formatDistanceToNowDate } from '@/utils/date'
import { replaceUsernamesWithMentions } from '@/utils/replaceUsernamesWithMentions'
import { actorMarkdown, safeCastUnknownToType } from '@/utils/utils'

import { FeedSections, OwnReaction, Pages, Verb } from '@enums'

export type IPostFeedDetailProps = IActivityItem & {
  attachments?: {
    images?: string[]
  }
  text?: string
  commentView?: boolean
  showActionButtons?: boolean
  hasOwnReactions: boolean
  reaction: {
    ownReactions: OwnReaction[]
  }
  addReaction: ({ activityId }: { activityId: string }) => void
  deleteReaction: ({ reactionId }: { reactionId: string }) => void
}

export const PostFeedDetail: FC<IPostFeedDetailProps> = ({
  actor,
  attachments,
  text,
  time,
  verb,
  reaction_counts,
  object,
  id,
  commentView,
  showActionButtons,
  own_reactions,
  hasOwnReactions,
  reaction,
  addReaction,
  deleteReaction,
}) => {
  const { t } = useTranslation('common')
  const { data: authUser } = useUserInfo()

  const isSubscriptionContent = object?.data?.isSubscriptionContent

  const trackReaction = useCallback(() => {
    Tracking.triggerEvent(TrackingEvent.PostLiked, {
      postId: id,
    })
  }, [id])

  const handleReactionClick = useCallback(async () => {
    if (hasOwnReactions) {
      const reactionId = reaction?.ownReactions?.[0]?.id as string | undefined

      deleteReaction({ reactionId })
      Tracking.triggerEvent(TrackingEvent.LikeDeleted, {
        activityId: id,
        contentId: (object?.data?.id || object?.id) ?? '',
        contentType: 'post',
        timestamp: formatISO(new Date()),
      })

      return
    }

    addReaction({ activityId: id })

    trackReaction()
  }, [
    hasOwnReactions,
    addReaction,
    id,
    object?.data?.id,
    object?.id,
    reaction?.ownReactions,
    deleteReaction,
    trackReaction,
  ])

  const markdownEllipsisText = useMemo(() => {
    const actorData = {
      url: `/@${actor?.data?.name}`,
      name: safeCastUnknownToType<string>(actor?.data?.name),
      isAmbassador: safeCastUnknownToType<boolean>(actor?.data?.isAmbassador),
      isModerator: safeCastUnknownToType<boolean>(actor?.data?.isModerator),
    }

    if (typeof object === 'object' && !!object?.data?.text) {
      const textWithMarkdown = replaceUsernamesWithMentions(
        safeCastUnknownToType<string>(object?.data?.text),
        object?.data?.mentions
      )

      return `${actorMarkdown(actorData)} ${textWithMarkdown}`
    }

    if (typeof object === 'string' && !!text) {
      return `${actorMarkdown(actorData)} ${text}`
    }

    return null
  }, [actor, object, text])

  const handleShareClick = useCallback(() => {
    Tracking.triggerEvent(TrackingEvent.PostShared, {
      activityId: id,
      contentId: object?.data?.id,
      contentType: 'post',
      contentOwnerId: object?.data?.userId,
      userId: authUser?.id,
      timestamp: formatISO(new Date()),
    })
  }, [id, object?.data?.id, object?.data?.userId, authUser?.id])

  return (
    <div className="relative !z-0">
      <div className="text-sm font-normal mx-3 mt-3 mb-1 whitespace-pre-line wordWrap-break text-cool-gray-200">
        <MarkdownEllipsis text={markdownEllipsisText} />
      </div>
      <div className="flex justify-between items-center ml-3 mb-3">
        <span className="block text-xs opacity-50 mb-3">
          {formatDistanceToNowDate({
            timestamp: String(time),
            addSuffix: true,
          })}
        </span>
        {showActionButtons && (
          <IonButtons className="items-start">
            <ReactionButton
              onClick={handleReactionClick}
              likesNumberPosition="right"
              reactionActivityId={id}
              own_reactions={own_reactions as OwnReaction[]}
              reaction_counts={reaction_counts}
            />
            {!commentView && (
              <div className={cx({ 'mr-3': isSubscriptionContent })}>
                <CommentsButton
                  color="plain"
                  activityId={id}
                  commentsNumber={reaction_counts?.COMMENT}
                  commentsNumberPosition="right"
                  page={Pages.Feed}
                  channel={FeedSections.Post}
                />
              </div>
            )}
            {!isSubscriptionContent && (
              <ShareButton
                className="flex justify-center !p-0 cursor-pointer"
                subject={object?.data?.text}
                message={t('UGC.post.postBy', {
                  userName: actor?.data?.name,
                })}
                buttonLabel={<Share className="cursor-pointer" />}
                url={`${appConfig.origin}/comments/${id}${
                  verb === Verb.ContestParticipate ? `/video/${actor?.id}` : ''
                }`}
                imageUrl={attachments?.images?.[0]}
                feature={`contest${
                  verb === Verb.ContestParticipate ? '-video' : ''
                }`}
                params={{ refUsername: String(authUser?.username) }}
                onClickShare={handleShareClick}
              />
            )}
          </IonButtons>
        )}
      </div>
    </div>
  )
}
