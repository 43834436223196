import { appConfig } from '@/services/config'

import { Platform, TrackingEvent } from './enums'

import { EventName, IIdentifyEvent } from './interface'
import { AWSKinesisDriver } from './platform/amplify'
import {
  AmplitudeDriver,
  amplitudeIdentifyEvent,
  amplitudeSetUserId,
} from './platform/amplitude'
import { GA4Driver } from './platform/ga'
import { GetStreamDriver } from './platform/getstream'
import { newrelicNoticeError } from './platform/newrelic'
import { TrackingSystem } from './tracking'

const amplitudeEvents: TrackingEvent[] = [
  TrackingEvent.Activity,
  TrackingEvent.AppOpened,
  TrackingEvent.ConsentConfirmed,
  TrackingEvent.Discover,
  TrackingEvent.Feed,
  TrackingEvent.LogInViewed,
  TrackingEvent.MessageSent,
  TrackingEvent.OtherProfileViewed,
  TrackingEvent.Profile,
  TrackingEvent.ProfileCreated,
  TrackingEvent.ProfileFollowed,
  TrackingEvent.ProfileUnfollowed,
  TrackingEvent.ReferralLinkClicked,
  TrackingEvent.ReferralSignupSubmitted,
  TrackingEvent.SigninCompleted,
  TrackingEvent.SigninMethodSelected,
  TrackingEvent.SigninViewed,
  TrackingEvent.SignUpViewed,
  TrackingEvent.SignUpStarted,
  TrackingEvent.StartPageViewed,
  TrackingEvent.XPViewed,
  TrackingEvent.FeedItemViewed,
  TrackingEvent.ExploreCategorySelected,
  TrackingEvent.LikeDeleted,
  TrackingEvent.ConversationShared,
  TrackingEvent.PostShared,
  TrackingEvent.SubmissionShared,
  TrackingEvent.ChallengeCreatorVideoShared,
  TrackingEvent.ChallengeShared,
  TrackingEvent.AddReplyCompleted,
  TrackingEvent.AddReplyStarted,
  TrackingEvent.AddContentStarted,
  TrackingEvent.AddPostCompleted,
  TrackingEvent.ChallengeCommented,
  TrackingEvent.ChallengeCreatorVideoCommented,
  TrackingEvent.ChallengeCreatorVideoViewed,
  TrackingEvent.ChallengeJoinCompleted,
  TrackingEvent.ChallengeViewed,
  TrackingEvent.ConversationLiked,
  TrackingEvent.ConversationCommented,
  TrackingEvent.PostLiked,
  TrackingEvent.SubmissionCommented,
  TrackingEvent.EarnMoreClicked,
  TrackingEvent.StakeNowCTAClicked,
  TrackingEvent.BuyButtonClicked,
]

const amplifyEvents: TrackingEvent[] = [
  TrackingEvent.FeedItemViewed,
  TrackingEvent.LikeDeleted,
  TrackingEvent.ConversationShared,
  TrackingEvent.PostShared,
  TrackingEvent.SubmissionShared,
  TrackingEvent.ChallengeCreatorVideoShared,
  TrackingEvent.ChallengeShared,
  TrackingEvent.OtherProfileViewed,
]

const getStreamEvents: TrackingEvent[] = [TrackingEvent.FeedItemViewed]

const Tracking = TrackingSystem.getInstance(
  [
    appConfig.amplitudeKey &&
      new AmplitudeDriver(appConfig.amplitudeKey, amplitudeEvents),
    appConfig.kinesis && new AWSKinesisDriver(amplifyEvents),
    appConfig.getstreamApiKey &&
      appConfig.getstreamAnalyticsToken &&
      new GetStreamDriver(getStreamEvents),
    new GA4Driver(amplitudeEvents),
  ].filter(Boolean)
)

export {
  Tracking,
  Platform,
  amplitudeIdentifyEvent,
  amplitudeSetUserId,
  TrackingEvent,
  newrelicNoticeError,
}
export type { IIdentifyEvent, EventName }
