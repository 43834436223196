import { fetchAuthSession } from 'aws-amplify/auth'

import { Amplify } from '@/services/awsAmplify'

import { appConfig } from '../../config'

Amplify.configure(
  {
    Auth: {
      Cognito: {
        // region: appConfig.region,
        userPoolId: appConfig.userPoolId,
        //userPoolWebClientId: appConfig.userPoolClientId,
        identityPoolId: appConfig.identityPoolId,
        userPoolClientId: appConfig.userPoolClientId,
      },
    },
    Storage: {
      S3: {
        bucket: appConfig.bucket,
        region: appConfig.region,
      },
    },
    API: {
      REST: {
        default: {
          endpoint: appConfig.api,
          region: appConfig.region,
        },
        cached: {
          endpoint: appConfig.cachedApi,
          region: appConfig.region,
        },
        notAuthenticated: {
          endpoint: appConfig.api,
          region: appConfig.region,
        },
      },
    },
    Analytics: {
      Kinesis: {
        region: appConfig.region,
        flushSize: 10,
      },
    },
  },
  {
    API: {
      REST: {
        headers: async (options) => {
          let authToken: string

          if (options?.apiName !== 'notAuthenticated') {
            authToken = (await fetchAuthSession()).tokens?.idToken?.toString()
          }

          const headers = {
            'X-Custom-App-Version-Tag': appConfig.versionTag,
            'X-Aws-Waf-Token': await window.AwsWafIntegration?.getToken(),
          }

          if (authToken) {
            headers['Authorization'] = `Bearer ${authToken}`
          }

          return headers
        },
      },
    },
  }
)
